









import { defineComponent } from '@vue/composition-api'

export const ConfettiNotice = defineComponent({})

export default ConfettiNotice
