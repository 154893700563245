


















import { ConfettiLoader } from '~/components/ConfettiLoader.vue'
import { defineComponent } from '@vue/composition-api'

interface Props {
  loading?: boolean
}

export const ConfettiButton = defineComponent<Props>({
  props: {
    loading: Boolean,
    disabled: Boolean,
  },
  components: {
    ConfettiLoader,
  },
})

export default ConfettiButton
