import * as Sentry from '@sentry/browser'
import axios, { AxiosError } from 'axios'
import { Competition, Game } from './types'

const api = axios.create({
  baseURL: '/api',
})

api.interceptors.response.use(undefined, (err: AxiosError) => {
  // capture 5XX errors
  if (
    !err.response ||
    (err.response.status >= 500 && err.response.status <= 599)
  )
    Sentry.captureException(err)
  return Promise.reject(err)
})

export const setSocketId = (socketId?: string) => {
  api.defaults.headers['x-socket-id'] = socketId
}

export const getSlugAndWhiteboardLinkByCode = async (code: string) => {
  const { data } = await api.get<{ slug: string; whiteboardLink: string; }>(`/code/${code}`)
  return data
}

export const getGameBySlug = async (slug: string) => {
  const { data } = await api.get<Game>(`/game/${slug}`)
  return data
}

export const joinGame = async (
  gameSlug: string,
  name: string,
  pusherId: string,
  teamKey: string,
) => {
  const { data } = await api.post<{ playerId: string; game: Game }>(
    `/game/${gameSlug}/join`,
    { name, pusherId, teamKey },
  )
  return data
}

export const changeTeam = async (
  gameSlug: string,
  playerId: string,
  teamKey: string,
) => {
  const { data } = await api.post<Game>(
    `/game/${gameSlug}/player/${playerId}/change-team`,
    { teamKey },
  )
  return data
}

export const setPlayerReady = async (gameSlug: string, playerId: string) => {
  const { data } = await api.post<Game>(
    `game/${gameSlug}/player/${playerId}/ready`,
  )
  return data
}

export const startRound = async (gameSlug: string, playerId: string) => {
  const { data } = await api.post<Game>(`game/${gameSlug}/round`, { playerId })
  return data
}

export const continueRound = async (
  gameSlug: string,
  roundKey: string,
  guessed: boolean,
) => {
  const { data } = await api.post<Game>(`game/${gameSlug}/round/${roundKey}`, {
    guessed,
  })
  return data
}

export const getCompetition = async (competitionId: string) => {
  const { data } = await api.get<Competition>(`competition/${competitionId}`)
  return data
}

export const getServerTime = async () => {
  const { data } = await api.get<number>('/now')
  return data
}
