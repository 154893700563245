
































































import { defineComponent, ref, watch } from '@vue/composition-api'
import ConfettiLogoSquare from '~/assets/ConfettiLogoSquare.svg?inline'
import { ConfettiButton } from '~/components/ConfettiButton.vue'
import { ConfettiNotice } from '~/components/ConfettiNotice.vue'
import { getSlugAndWhiteboardLinkByCode } from '~/utils/api'

export default defineComponent({
  components: {
    ConfettiButton,
    ConfettiNotice,
    ConfettiLogoSquare,
  },
  setup(_, { root }) {
    const code = ref<string>()
    const isCodeValid = ref(false)
    const errorMessage = ref<string>()
    const isPendingJoin = ref(false)

    const join = async () => {
      if (!code.value) return

      try {
        isPendingJoin.value = true
        const { slug } = await getSlugAndWhiteboardLinkByCode(code.value)
        await root.$router.replace({ path: `game/${slug}` })
      } catch (err) {
        errorMessage.value = err.response?.data || err.message
      } finally {
        isPendingJoin.value = false
      }
    }

    const onCodeValidation = ({ target }: Event) => {
      const input = target as HTMLInputElement
      const {
        badInput,
        tooShort,
        patternMismatch,
        valueMissing,
      } = input.validity
      const isInvalid = badInput || tooShort || patternMismatch || valueMissing

      input.setCustomValidity(isInvalid ? 'Code should be 5 numbers' : '')
      isCodeValid.value = !isInvalid
    }

    const onInput = ({ target }: Event) =>
      (target as HTMLInputElement).checkValidity()

    // reset error
    watch(code, () => {
      errorMessage.value = undefined
    })

    return {
      maxlength: 5,
      join,
      code,
      isCodeValid,
      isPendingJoin,
      errorMessage,
      onInput,
      onCodeValidation,
    }
  },
})
