











import { defineComponent } from '@vue/composition-api'

export const ConfettiLoader = defineComponent({})

export default ConfettiLoader
